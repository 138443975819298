.video {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 0.2);
	backdrop-filter: blur(5px);
	z-index: 10;
	pointer-events: none;
	user-select: none;
	opacity: 0;
	transition: opacity ease 1s;
}

.video.show {
	opacity: 1;
}

.videoName {
	position: fixed;
	color: #fff;
	font-size: 4vw;
	text-transform: uppercase;
	font-weight: 600;
	z-index: 30;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	user-select: none;
	position: none;
	text-align: center;
	opacity: 0;
	width: 100%;
}

.videoName.horizontal {
	font-size: 7.25vh;
}

.videoName.show {
	opacity: 1;
}

.videoName.hide {
	opacity: 0;
}

.videoName.show.fade {
	transition: opacity linear 1s;
}

.videoName.hide.fade {
	transition: opacity linear 1.65s;
}

.participant {
	font-size: 133%;
	font-weight: 700;
	margin-top: 2.9vw;
}

.videoName.horizontal .participant {
	margin-top: 4vh;
}

.toggleMuted {
	position: absolute;
	top: 13%;
	right: 0;
	background-color: rgb(0 0 0 / 0.5);
	padding: 0.9rem 1.2rem;
	border-radius: 1rem 0 0 1rem;
	z-index: 15;
	cursor: pointer;
	user-select: none;
	opacity: 0.4;
	transition: opacity ease 0.3s;
}

.toggleMuted img {
	width: 2rem;
	height: 2rem;
}

.toggleMuted:hover {
	opacity: 1;
}

@media (max-width: 991px) {
	.toggleMuted {
		padding: 0.7rem 1rem;
	}
	.toggleMuted img {
		width: 1.8rem;
		height: 1.8rem;
	}
}