.form {
	width: 70%;
	--input-height: 3.2rem;
	--input-font-size: 1rem;
	--input-border-radius: 0.8rem;
	--input-padding-x: 1.2rem;
	--input-padding-y: 0.8rem;
	--select-padding-x: 0.7rem;
}

.inputGroup {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #001c4b;
	padding: 0 0 0 1.5rem;
	border-radius: var(--input-border-radius);
	margin-bottom: 1.5rem;
}

.inputGroup label {
	color: #fff;
	line-height: 1.3;
	font-size: var(--input-font-size);
	width: 50%;
}

.input {
	font-family: 'Montserrat', sans-serif;
	width: 50%;
	height: var(--input-height);
	border-radius: var(--input-border-radius);
	outline: none;
	padding: var(--input-padding-y) var(--input-padding-x);
	font-size: var(--input-font-size);
	border-style: solid;
	border-width: 1px;
	border-color: transparent;
	background: rgb(204, 224, 237);
	background: linear-gradient(90deg, rgba(204, 224, 237, 1) 0%, rgba(227, 231, 237, 1) 100%);
}

.input:focus {
	border-color: #2684ff;
	box-shadow: 0 0 0 1px #2684ff;
}

.selectContainer {
	width: 50%;
}

.selectControl {
	border-radius: var(--input-border-radius) !important;
	height: var(--input-height) !important;
	background: rgb(204, 224, 237);
	background: linear-gradient(90deg, rgba(204, 224, 237, 1) 0%, rgba(227, 231, 237, 1) 100%);
	padding-left: var(--select-padding-x);
	padding-right: var(--select-padding-x);
	font-size: var(--input-font-size);
}

.submit {
	font-family: 'Montserrat', sans-serif;
	border: 2px solid rgb(255 255 255 / 0.6);
	background: rgb(51, 177, 255);
	background: radial-gradient(circle, rgba(51, 177, 255, 1) 0%, rgba(86, 201, 255, 1) 100%);
	border-radius: var(--input-border-radius);
	height: var(--input-height);
	font-size: 1.7rem;
	font-weight: 700;
	color: #fff;
	display: block;
	margin: auto;
	padding: 0 3.5rem;
	text-transform: uppercase;
	cursor: pointer;
	box-shadow: 0 0 8px 3px rgb(48 147 252 / 0.6);
	text-shadow: 0 2px 10px rgb(0 0 0 / 0.7);
	margin-top: 2.5rem;
}

@media (max-width: 1199px) {
	.form {
		width: 90%;
	}
}

@media (max-width: 991px) {
	.form {
		width: 100%;
		--input-height: 2.5rem;
		--input-font-size: 0.8rem;
		--input-border-radius: 0.7rem;
		--input-padding-x: 1rem;
		--input-padding-y: 0.6rem;
		--select-padding-x: 0.5rem;
	}
	.submit {
		font-size: 1.4rem;
	}
}

@media (max-width: 767px) {
	.form {
		--input-height: 2.5rem;
		--input-border-radius: 0.6rem;
		--input-padding-x: 0.8rem;
		--input-padding-y: 0.5rem;
		--select-padding-x: 0.3rem;
	}
	.inputGroup {
		padding: 0 0 0 1rem;
	}
	.submit {
		font-size: 1.2rem;
		padding: 0 3rem;
	}
}