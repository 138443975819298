.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	background-color: rgb(0 0 0 / 0.7);
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;
	user-select: none;
}

.spinner {
	width: 3.5rem;
	height: 3.5rem;
	animation-name: spin;
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}